@font-face {
    font-family: "tiktok";
    font-style: normal;
    //font-weight: 400;
    // src: url(../../assets/font/tiktok.ttf);
    src: url(../../assets/font/TikTokText-Medium.ttf);
    font-display: swap;
}


