@import "./component/font";

.cursor_default {
  cursor: default !important;
  .input-wrapper {
    input {
      cursor: default !important;
    }
  }
}
//col
.col-0 {
  width: 0%;
}
.col-0_5 {
  width: 5%;
}
.col-1 {
  width: 10%;
}
.col-1_5 {
  width: 15%;
}
.col-2 {
  width: 20%;
}
.col-2_5 {
  width: 25%;
}
.col-3 {
  width: 30%;
}
.col-3_5 {
  width: 35%;
}
.col-4 {
  width: 40%;
}
.col-4_5 {
  width: 45%;
}
.col-5 {
  width: 50%;
}
.col-5_5 {
  width: 55%;
}
.col-6 {
  width: 60%;
}
.col-6_5 {
  width: 65%;
}
.col-7 {
  width: 70%;
}
.col-7_5 {
  width: 75%;
}
.col-8 {
  width: 80%;
}
.col-8_5 {
  width: 85%;
}
.col-9 {
  width: 90%;
}
.col-9_5 {
  width: 95%;
}
.col-10 {
  width: 100%;
}

.mt-2-5 {
  margin-top: 0.625rem;
}

td {
  position: relative;
}

.blackground-none {
  background-color: #00000000;
}

.o_backgroung_color {
  background-color: #ea580c;
  color: white;
}

.o_backgroung_color:hover {
  background-color: #f97316;
}
// chat page

.card-chat-div {
  border: 0.1px solid rgba(0, 0, 0, 0.11);

  .card-chat-header {
    background: #f0f2f5;
    padding: 0.8em 0.3em;
    .icon-search {
      position: absolute;
      left: 11%;
    }
    .icon-up {
      position: absolute;
      right: 19%;
      cursor: pointer;
    }
    .icon-down {
      position: absolute;
      right: 11%;
      cursor: pointer;
    }
    .search-message-bar {
      width: 80%;
      outline: none;
      padding-right: 3.4em;
      padding-left: 2em;
      border-radius: 8px;
      background: #ffffff;
      height: 28px;
    }
  }
  .card-chat-body {
    position: relative;
    // background-size: cover;
    .loading-page {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(145, 142, 142, 0.42);
      z-index: 13;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      label {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 1.2rem;
      }
    }
    .chat-list {
      overflow: scroll;
      .date-div {
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        &.error {
          margin-top: 0.7em;
          text-align: end;
          padding-right: 2.2em;
          ._label {
            &.--date {
              border-radius: 0.3em;
              padding: 0.35em 0.4em;
              font-size: 12.5px;
              background: #fcf8f0;
              color: #ed1c24;
            }
          }
        }
        &.warning {
          width: 36em;
          margin: auto;
          margin-top: 1em;
          max-width: 80%;
          border-radius: 0.5em;
          background: #ffeecd;
          ._label {
            &.--date {
              border-radius: 0.3em;
              padding: 0.35em 0.4em;
              font-size: 12.5px;
              color: #566771;
              background: none;
            }
          }
        }
        ._label {
          &.--date {
            border-radius: 0.3em;
            padding: 0.35em 0.4em;
            font-size: 12.5px;
            color: #566771;
            background: hsla(0, 0%, 100%, 0.95);
          }
        }
      }
      .file-div {
        border-radius: 1em;
        position: absolute;
        bottom: 0;
        left: 7%;
        height: auto;
        background: #ffffff;
        box-shadow:
          0 2px 5px 0 rgba(11, 20, 26, 0.26),
          0 2px 10px 0 rgba(11, 20, 26, 0.16);
        max-width: 207.78px;
        width: 100em;
        padding: 0.5em;
        .file-li {
          cursor: pointer;
          padding: 0.1em;
          border-radius: 0.4em;
        }
        .file-li:hover {
          background: #f0f2f5;
        }
      }
      ._label {
        &.--li {
          cursor: pointer;
          font-size: 15px;
          color: #3b4a54;
          font-weight: 550;
        }
      }
    }
    .chat-list::-webkit-scrollbar {
      display: none !important;
    }
    .message-row {
      padding: 0.2em 2.4em;
      margin-bottom: 0.2em;
      align-items: center;
      display: flex;

      @media (max-width: 450px) {
        padding: 0.2em 0.8em !important;
      }

      @media (max-width: 950px) and (min-width: 770px) {
        padding: 0.2em 0.8em !important;
      }

      .tail-out {
        position: absolute;
        height: 12px;
        width: 12px;
        top: -1px;
        z-index: 12;
        border-top: 14px solid transparent;
      }

      .message-card {
        position: relative;
        max-width: 60%;
        padding: 5px 5px 8px;
        background: #d9fdd3;
        border-radius: 7px;

        @media (max-width: 950px) and (min-width: 770px) {
          max-width: 80%;
        }

        @media (max-width: 450px) {
          max-width: 80%;
        }

        .message-image {
          position: relative;
          // width: 330px;
          // height: 185px;
          .p-image-preview-container {
            min-width: 100%;
          }
          &.Android {
            img {
              max-height: 285px;
            }
          }
          &.iOS {
            img {
              max-height: unset !important;
              //    max-width: 300px;
              //    width: auto;
              //    object-fit: cover;
            }
          }
          img {
            max-height: 285px;
            object-fit: cover;
            //    max-width: 200px;
            //    min-width: 200px;
            width: 100%;
            height: 100%;
          }
          .video-card {
            max-width: 369.22px;
            min-width: 280px;
            max-height: 300px;
            width: 100%;
            height: 100%;
            video {
              max-height: 300px;
              object-fit: cover;
            }
          }
          &.mobile {
            .video-card {
              // max-width: 247.8px;
              min-width: unset !important;
              max-height: 300px;
              width: 100%;
              height: 100%;
              video {
                max-height: 300px;
                object-fit: cover;
              }
            }
          }
          &.medium {
            .video-card {
              max-width: 369.22px;
              min-width: 220px;
              max-height: 300px;
              width: 100%;
              height: 100%;
              video {
                max-height: 300px;
                object-fit: cover;
              }
            }
          }
          &.ipad {
            .video-card {
              max-width: 280px;
              min-width: 120px;
              max-height: 300px;
              width: 100%;
              height: 100%;
              video {
                max-height: 300px;
                object-fit: cover;
              }
            }
          }
        }
        ._label {
          &.--message {
            color: #111b21;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.3em;
            word-break: break-word;
            white-space: pre-wrap;
          }
          &.--file_name {
            display: -webkit-box !important;
            cursor: pointer;
            color: #222e30;
            font-size: 14.2px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            word-break: break-all;
            -webkit-box-orient: vertical;
          }
          &.--file_size {
            cursor: pointer;
            color: #8a9ca3;
            font-size: 11px;
            text-transform: uppercase;
          }
        }
        ._span {
          &.--time {
            font-size: 9.5px;
            color: #667781;
            line-height: normal;
            padding-right: 0.5em;
          }
          &.image_time {
            color: #ffffff;
            // font-weight: 800;

            position: absolute;
            bottom: 8%;
            right: 5%;
          }
          &.video_time {
            color: #ffffff;
            // font-weight: 800;

            position: absolute;
            bottom: 6%;
            right: 3%;
            transform: translate(-10%, -50%);
            padding: 0;

            svg {
              color: #ffffff !important;
            }

            // @media (max-width: 770px) {
            //   bottom: 10%;
            //   right: 5%;
            // }
          }
        }
        .documnet-row {
          margin-top: 0.5em;
          min-width: 13em;
          cursor: pointer;
          border-radius: 0.4em;
          padding: 0.35em 1em;
          background: #e8fae5;
          border: "1px solid #0bd09d17";
          box-shadow: 0 1px 3px #0000001c;
          display: flex;
          align-items: center;
          .left-col {
            margin: auto;
            justify-content: center;
            display: flex;
          }
          .file-col {
            display: flex;
            align-items: center;
          }
        }
        .audio-ui {
          audio {
            background: none;
          }
        }
      }
    }
    .EmojiPickerReact {
      height: 300px !important;
      bottom: 0;
      width: 100% !important;
      position: absolute;
      .epr-emoji-category-label {
        display: none !important;
      }
      .epr-preview {
        display: none !important;
      }
    }
    .image-time-div {
      position: absolute;
      bottom: 10px;
      right: 7px;
      &.mobile {
        right: 15px;
      }
      &.ipad {
        right: 15px;
      }
      &.medium {
        right: 17px;
      }
      &.desktop {
        right: 17px;
      }
      &.big_desktop {
        right: 19px;
      }
    }
  }
  .card-chat-footer {
    display: flex;
    align-items: center;
    background: #f0f2f5;
    padding: 0.6em 0.3em;
    .chat-input {
      width: 100%;
      border-radius: 0.3em;
      height: 2.5em;
      padding-left: 1em;
      background: #ffffff;
      color: #3b4a54;
      box-shadow: none;
      outline: none;
    }
    .chat-textarea {
      width: 100%;
      border-radius: 0.3em;
      height: 2.5em;
      padding-top: 0.5em;
      padding-left: 1em;
      background: #ffffff;
      color: #3b4a54;
      box-shadow: none;
      outline: none;
      font-size: 14px;
    }
  }
  .avatar-div {
    height: 40px;
    width: 40px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  ._label {
    &.--name {
      font-size: 16px;
      color: #111b21;
      font-weight: 600;
    }
  }
  ._p {
    &.--message {
      font-size: 13px;
      color: #111b21;
      white-space: pre-wrap;
    }
  }
  .update-image-div {
    background: #e9edef;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    width: 75%;
    .update-image-header {
      margin-top: 2em;
      padding: 0em 1.5em;
      align-items: center;
      display: flex;
    }
    .update-image {
      max-height: 420.5px;
      margin-top: 7em;
      padding: 0em 4em;
      &.image {
        margin: auto !important;
        margin-top: 7em !important;
      }
      &.video {
        margin-top: 7em;
      }
      .video-card {
        padding: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        max-height: 420.5px;
        width: unset !important;
        video {
          width: unset !important;
          max-height: 420.5px;
          // height: initial !important;
        }
      }
      img {
        max-height: 420.5px;
        height: 100%;
        width: 100%;
        // object-fit: cover;
        border-radius: 0.2em;
      }
    }
    .update-document {
      max-width: 100%;
      max-height: 420.5px;
      margin-top: 14em;
      text-align: center;
      .document-col {
        justify-content: center;
        display: flex;
      }
    }
    .update-image-footer {
      align-items: center;
      display: flex;
      justify-content: center;

      .add-button {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        margin-top: 3em;
        margin-right: 1em;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.end {
        position: relative;
        .send-icon {
          cursor: pointer;
          padding: 0.55em;
          border-radius: 50%;
          background: #00a884;
          position: absolute;
          right: 10%;
          bottom: -60px;
        }
      }
      .message-input {
        margin-top: 5em;
        padding-left: 1em;
        border-radius: 0.5em;
        height: 3.5em;
        background: #ffffff;
        color: #3b4a54;
        box-shadow: none;
        outline: none;
        width: 50%;
      }
      .message-textarea {
        margin-top: 3em;
        padding-left: 1em;
        padding-top: 0.7em;
        border-radius: 0.5em;
        height: 2.9em;
        background: #ffffff;
        color: #3b4a54;
        box-shadow: none;
        outline: none;
        width: 60%;
      }
      .send-btn {
        margin-top: 3em;
        margin-left: 0.5em;
        cursor: pointer;
        padding: 0.55em;
        border-radius: 50%;
        background: transparent !important;
      }
    }
  }
}

.user-chat-div {
  border: 0.1px solid rgba(0, 0, 0, 0.11);
  background: #ffffff;
  height: 100%;
  .user-chat-header {
    display: flex;
    align-items: "center";
    padding: 0.8em 0.3em;
    background: #f0f2f5;
    .avatar-div {
      height: 38px;
      width: 38px;
      margin: auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    ._label {
      &.--name {
        font-size: 16px;
        color: #111b21;
        font-weight: 600;
      }
    }
  }
  .user-chat-search {
    padding: 1em 0.7em 0.7em;
    align-items: center;
    display: flex;
    border-bottom: 0.1px solid #ffffff82;

    .search-bar {
      width: 100%;
      border-radius: 0.3em;
      height: 2.5em;
      padding-left: 1em;
      background: #f0f2f5;
      color: #3b4a54;
      box-shadow: none;
      outline: none;
    }

    input {
      padding-right: 5.5rem;
      font-size: 14px;
      height: 2.5rem !important;
    }
  }
  .message-div {
    overflow: scroll;

    .user-card:has(+ .--active) {
      border: 0; /* Style the previous element */
    }

    .user-card {
      transition: background border-radius 0.4s ease;
      cursor: pointer;
      display: flex;
      gap: 0.6em;
      padding: 0.7em 0.5em;
      border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);

      &.--active {
        background: #f0f2f5;
        border-radius: 8px;
        border: 0;
      }

      &.desktop {
        .user-avatar {
          height: 46px !important;
          width: 46px !important;
        }
      }
      .user-avatar {
        height: 40px;
        width: 40px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      ._label {
        cursor: pointer;

        &.--name {
          font-size: 15px;
          color: #111b21;
          font-weight: 510;
        }
        &.--time {
          font-size: 12px;
          font-weight: 500;
          white-space: pre;
        }
        &.--small {
          font-size: 12px;
          color: #111b21;
          font-weight: 500;
        }
      }
      ._p {
        &.--message {
          color: #667781;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          align-items: center;
          font-size: 13px;
          line-height: 16px;
          // white-space: pre-wrap;
        }
        &.--notification {
          background: #23bb6c;
          border-radius: 50%;
          color: white;
          font-size: 11px;
          min-width: 18px;
          min-height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -0.1em;
        }
      }
    }
    .user-card:hover {
      background: #f0f2f59c;
    }
  }
  .message-div::-webkit-scrollbar {
    display: none !important;
  }
}

.search-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.duty_tax-div {
  // .css-b62m3t-container{
  //     // font-size: 1.1rem
  // }
  .css-aoxytf-singleValue {
    padding: 0.5em;
    font-size: 1em;
  }
}

//header
.header-action {
  gap: 0.25em;

  .notification-btn {
    padding: 0.3em 0.6em !important;
    font-size: 0.8rem !important;
    width: 30px;
  }
  ._label {
    &.--notification {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
    }
  }
  .notification-icon-div {
    border-radius: 50%;
    padding: 0.5em 0.3em;
  }
  .notification-icon-div:hover {
    cursor: pointer;
    background: rgb(255, 231, 231);
    .notification-icon {
      color: rgb(236 49 49);
    }
  }
}
// notification
.drawer-body {
  padding: 0em;
  .notification-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #a9a9a9;
    padding: 1em 1em 0.5em 1em;
    .calender-icon-div {
      width: 15%;
      display: flex;
      justify-content: center;
      .calender-icon-background {
        background: #dbeafe;
        border-radius: 50%;
        padding: 0.3em;
      }
    }
    .description-div {
      cursor: pointer;
      padding: 0.3em;
      width: 80%;
      ._label {
        cursor: pointer;
        display: flex;
        &.--description {
          font-size: 13px;
          line-height: 16px;
        }
        &.--date {
          margin-top: 0.3em;
          color: #6b7280;
          font-size: 12px;
        }
      }
    }
  }
  .notification-item:hover {
    cursor: pointer;
    background: rgba(226, 220, 220, 0.41);
  }
}

//hover effect for card.js
.card.cursor-pointer:hover {
  background-color: rgba(241, 241, 241, 0.548);
  transition: background-color 0.3s ease;
}

//VerticalCollapsedMenuItem.js whatsapp notification
.notification-badge-whatsapp {
  color: rgb(255, 255, 255);
  background-color: rgb(17, 155, 36);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
}

.notification-badge {
  color: rgb(236, 49, 49);
  background-color: rgb(255, 231, 231);
  border-radius: 20px;
  text-align: center;
  line-height: 24px;
  min-width: 25px;
  min-height: 24px;
  width: fit-content;
  padding: 0 0.8em;
  display: flex;
  place-content: center;

  &.--whatsapp {
    color: #1daa61;
    background-color: #caf3de;
  }

  &.--p-0 {
    padding: 0 !important;
  }
}

.infinite-scroll-component {
  &.--wallet {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f0f0f0c2;
    }

    padding: 0 0.6em;
  }
}

.whatsapp-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow: scroll; /* Enable vertical scrollbar if needed */
  // scrollbar-width: none; /* Hide scrollbar on Firefox */
  // -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */

  &::-webkit-scrollbar {
    display: none !important;
  }

  .contact-info {
    text-align: start;
    max-width: 400px;
    @media (max-width: 991px) {
      max-width: 280px;
    }

    h3 {
      margin-bottom: 5px;
      text-align: center;
      font-size: 18px;
      @media (max-width: 991px) {
        font-size: 15px;
      }
      padding-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin: 5px 0;
      font-size: 15px;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }

  .avatar-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid #ffffff;
    }

    h2 {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.container-style {
  @media (max-width: 991px) {
    padding: 10px 12px;
  }
  .label-style {
    font-size: 24px;
    @media (max-width: 991px) {
      font-size: 15px;
    }
  }
  .label-style-red {
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
}

// .icon-container-hover {
//   transition: border-color 0.3s ease;

//   .bell-icon:hover {
//     border-radius: 50%;
//     color: #d8a600;
//   }
// }

.bell-bg {
  margin-left: 1.2rem;
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  width: 43px;
  height: 42px;
  background: #ff925930;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ea580c;
    margin: 0 0 0.1em 0.06em;
  }
}

.p-highlight {
  border-color: transparent !important;
}

.notification-dialog {
  // width: 400px; // Adjust width as needed
  // padding: 20px;
  // background-color: #b89898;
  // border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
}

.notification-container {
  max-height: 300px; // Set the maximum height for the container
  overflow-y: auto; // Enable vertical scroll if content exceeds container height

  .no-notifications {
    padding: 10px;
    text-align: center;
    color: #888;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    .notification-row {
      border-bottom: 1px solid #ccc; // Add a bottom border between rows
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
      }

      td {
        @media (max-width: 991px) {
          padding: 13px 5px;
        }
        padding: 13px 10px;
        color: #333;
        font-size: 1rem;
        text-align: left;
        position: relative;
        cursor: context-menu;

        &:first-child {
          width: 20px;
          text-align: center;
          vertical-align: top;
        }
      }

      &.unread {
        td:first-child .bullet-new {
          display: block;
        }

        td:first-child .bullet-seen {
          display: none;
        }

        td:nth-child(2) {
          font-weight: 600;
        }
      }

      td:first-child {
        .bullet-new,
        .bullet-seen {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
        }

        .bullet-new {
          background-color: red;
        }

        .bullet-seen {
          background-color: lightgrey;
        }
      }
    }
  }
}

.preorderCalculation-dialog {
  display: flex; /* Use flexbox to manage child elements */
  flex-direction: column; /* Arrange children in a column */
  height: 100%; /* Fill the height of the parent Dialog */

  .firstDiv {
    flex: 1;
    overflow-y: auto;
    max-height: 560px;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.custom-table-preorder {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  .row {
    display: flex;
    .cell {
      flex: 1;
      border: 1px solid #ccc;
      padding: 8px;
      text-align: center;

      &.single-cell {
        flex-basis: calc(50% - 1px);
      }

      &.blue-bg {
        background-color: #cfe5f6;
      }

      &.blue-text {
        color: #1185d8;
        text-align: start;
      }

      &.white-text {
        color: white;
        background-color: #189cfb;
      }
    }
  }
}

//Salews Order - start
.time-line-div {
  margin-top: 1em;
  padding: 0em 4em;
  margin-bottom: 1em;
  background: #fbfbfb;
  .p-timeline-event-opposite {
    display: flex;
    align-items: center;
    min-height: 43.5px;
    font-size: 12px;
    padding: 0 !important;
    text-align: left !important;
  }
  .time-div {
    font-size: 12px;
  }
}

.forms-option-div {
  margin-top: 2em;
  margin-bottom: 2em;
  align-items: center;
  text-align: center;
  .option-item {
    padding: 0.4em;
    width: 16.6%;
    text-align: center;
    .border-div {
      padding: 0.2em;
      border-radius: 0.6em;
      box-shadow:
        rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      cursor: pointer;
      &.active {
        box-shadow:
          rgba(234, 88, 12, 0.25) 0px 4px 8px -2px,
          rgba(234, 88, 12, 0.08) 0px 0px 0px 1px;
        // border: 2px solid rgb(234, 88, 12);
        // background:rgba(247, 243, 241, 0.532) ;
      }
    }
  }
}

.action-details-div {
  display: flex;
  gap: 26px;
  justify-content: center;
  .basic-form-div {
    margin-top: 1em;
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #fbfbfb;

    .basic-form-header {
      padding: 0.5em;
      text-align: center;
      border-bottom: 1px solid #dadde0;
      background: #f8fafb;
      ._label {
        &.--header {
          color: #181c32;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .basic-item {
      padding: 0em 1em;
      margin: 0.8em 0em;
      display: flex;
      align-items: center;
      ._label {
        &.--title {
          color: #6b7280;
          font-size: 12px;
          font-weight: 550;
        }
        &.--value {
          color: #181c32;
          font-size: 12px;
          font-weight: 550;
        }
      }
      .start-div {
        width: 50%;
        text-align: left;
      }
      .end-div {
        width: 50%;
        text-align: right;
      }
    }
  }
  .active-log-div {
    margin-top: 1em;
    border-radius: 10px;
    border: 1px solid #ddd;
    background: #fbfbfb;
    padding: 0em 1em 1em 1em;
    .active-log-header {
      padding: 0.5em;
      text-align: center;
      border-bottom: 1px solid #dadde0;
      background: #f8fafb;
      ._label {
        &.--header {
          color: #181c32;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .active-log-body {
      height: auto;
      .payment-details-div {
        ._label {
          &.--data {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            overflow: hidden;
          }
        }
      }
      ._label {
        &.--data {
          color: #808082;
          font-size: 13px;
          line-height: 8px;
          font-weight: 500;
        }
        &.--list-title {
          color: #181c32;
          text-align: center;
          font-size: 13px;
          font-weight: 600;
        }
        &.--confirm {
          color: #ea580c;
          font-size: 14px;
          font-weight: 600;
        }
        &.--s_url:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .active-log-history {
      margin-top: 1em;
      padding: 1em;
      .history_item {
        padding-bottom: 2rem;
        position: relative;
        padding-left: 2.2rem;
        border-left: 1px solid #ebe9f1;
        .avatar_div {
          white-space: nowrap;
          border-radius: 50%;
          position: absolute;
          display: inline-flex;
          text-align: center;
          vertical-align: middle;
          height: 30px;
          width: 30px;
          left: -15px;
          img {
            border-radius: 50%;
          }
        }
        ._label {
          &.--name {
            color: #181c32;
            font-size: 14px;
            font-weight: 600;
          }
          &.--time {
            color: #6b7280;
            font-size: 12px;
          }
        }
      }
    }
    .image-div {
      overflow: hidden;
      height: 150px;
      width: 100%;
      .image-backcover {
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .image-backcover:hover {
        background: #34343465;
      }
      .p-image-preview-container {
        height: 150px;
        width: 100%;
        object-fit: cover;
        img {
          height: 150px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.summary-border {
  padding: 6px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #cdcdcd;
  min-height: 4em;
  p {
    font-size: 12px;
    line-height: 15px;
    text-align: start;
  }

  ._label {
    &.--confirm {
      color: #ea580c;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &.active {
    border: 2px solid #ea580c;
  }
}

.invoice {
  // white-space: pre;
  color: #181c32;
  font-size: 12px;
  font-weight: 550;
  width: 60%;
  text-align: end;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.invoice-price {
  width: 40%;
  word-break: break-word;
  color: #181c32 !important;
  font-size: 12px !important;
  font-weight: 550 !important;
  text-align: left;
  padding-left: 1.5em;

  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sales-info-form-div {
  // text-align: center;
  .info-item {
    display: flex;
    align-items: center;
    margin: 0.6em 0em;

    &.--invoice {
      display: block;

      ._label {
        &.--title {
          width: unset;
        }
        &.--value {
          width: unset;
        }
        &.--s_value {
          text-align: end;
          width: 60%;
          word-break: break-all;
        }
      }
    }

    ._label {
      &.--title {
        text-align: end;
        width: 40%;
        color: #6b7280;
        font-size: 12px;
        font-weight: 550;
      }
      &.--value {
        width: 60%;
        color: #181c32;
        font-size: 12px;
        font-weight: 550;
        white-space: pre-line;
      }
      &.--s_value {
        text-align: left;
        width: 35%;
        color: #4081ff;
        font-size: 12px;
        font-weight: 550;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        .invoice {
          width: 40%;
        }
      }
      &.--s_value:hover {
        text-decoration: underline;
      }
    }

    .icon-div {
      width: 25%;
      display: flex;
      align-items: center;
      // justify-content: space-evenly;
    }

    .link {
      width: 60%;
      font-size: 12px;
      color: #1185d8;
      text-decoration: underline;
    }
  }
}

.mark-complete-btn {
  border: 1px solid #02bd15;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.reject_btn {
  background: #fbfbfb;
  color: #5c5c5c;
  border: 1px solid #d2d2d2;
}

.reject_btn:hover {
  background: #cbcbcb2b;
  color: #5c5c5c;
  border: 1px solid #d2d2d2;
}

.create_btn {
  background: #fbfbfbb9;
  color: #5c5c5c;
  border: 1px solid #d2d2d2;
}

.create_btn:hover {
  background: #cbcbcb2b;
  color: #5c5c5c;
  border: 1px solid #d2d2d2;
}

.delete_btn {
  background: #fbfbfb;
  color: #5c5c5c;
  border: 1px solid #d2d2d2;
}

.delete_btn:hover {
  background: rgb(235, 64, 64);
  color: #ffffff;
  border: 1px solid #d2d2d2;
}

.download_icon_btn {
  padding: 0.1em 1.5em;
}

//Sales Order - close

//Form List - Open

.filter-div {
  .select-btn {
    max-width: 30% !important;
    min-width: 50%;
    .select__control {
      cursor: pointer;
      border-radius: 0.375rem;
      border-style: solid;
      border-width: 1px;
      box-shadow: none;
      box-sizing: border-box;
      background-color: hsl(0, 0%, 100%);
      border-color: #d1d5db;
      border-width: 2px;
      // border: 2px solid hsl(0, 0%, 100%);
    }
    .select__control--menu-is-open {
      border-color: #ea580c !important;
      border-width: 2px;
    }
    .select__menu-list {
      color: #374151;
      .select__option--is-focused {
        background: #d7d8d942;
        cursor: pointer;
      }
      .select__option--is-selected {
        background: #d7d8d942;
        color: #374151;
      }
    }
  }
}

.select-btn {
  max-width: 30% !important;
  min-width: 30%;
  &.mobile {
    min-width: 50% !important;
  }
  .select__control {
    cursor: pointer;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    box-sizing: border-box;
    background-color: hsl(0, 0%, 100%);
    border-color: #d1d5db;
    border-width: 2px;
    // border: 2px solid hsl(0, 0%, 100%);
  }
  .select__control--menu-is-open {
    border-color: #ea580c !important;
    border-width: 2px;
  }
  .select__menu-list {
    color: #374151;
    .select__option--is-focused {
      background: #d7d8d942;
      cursor: pointer;
    }
    .select__option--is-selected {
      background: #d7d8d942;
      color: #374151;
    }
  }
}

.tab-view {
  margin-top: 1em;

  .tab-title {
    font-size: 14px;
  }
  .p-tabview-nav-link {
    outline: none;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  .p-tabview-selected {
    .p-tabview-nav-link {
      color: #ea580c !important;
      border-color: #ea580c !important;
    }
  }
  .p-tabview-ink-bar {
    background-color: #ea580c !important;
    z-index: 0 !important;
  }
}
.p-tabview {
  .p-tabview-panels {
    padding: 1em 0.8em !important;
  }
}

.form-card-div {
  padding: 1em;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background: #f6f6f6;
  .card-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }
  ._label {
    &.--title {
      font-family: "tiktok" !important;
      color: #181819;
      font-size: 13.5px;
      // font-weight: 550;
    }
    &.--value {
      // color: #898786;
      color: #1060ff;
      font-size: 13px;
      font-weight: 500;
      font-family: unset;
      // font-family: monospace;
    }
    &.--s_value {
      // color: #1060FF;
      color: #ff2b20;
      font-size: 13px;
      font-weight: 600;
    }
  }
  .status-bage {
    border: 2px solid #ff8e54;
    border-radius: 1em;
    font-size: 12px;
    text-align: center;
    padding: 0.5em;
    font-weight: 600;
  }
  .image-div {
    overflow: hidden;
    height: 150px;
    width: 100%;
    .image-backcover {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .image-backcover:hover {
      background: #34343465;
    }
    .p-image-preview-container {
      height: 150px;
      width: 100%;
      object-fit: cover;
      img {
        height: 150px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .link {
    width: 60%;
    font-size: 12px;
    color: #1185d8;
    text-decoration: underline;
    cursor: pointer;
  }
}

//Form List - close

//purchase_details_model

.displayData-div {
  .value-div {
    ._label {
      &.--s_value {
        text-decoration: underline;
        text-align: left;
        color: #4081ff;
        font-size: 12px;
        font-weight: 550;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        text-overflow: ellipsis;
      }
      &.--s_value:hover {
        text-decoration: underline;
      }
    }
  }
}

//Form Field
.newFile {
  .my-16 {
    margin-top: -2.4em;
  }
  .upload-draggable-div {
    min-height: 3rem;
    max-height: 3rem;
  }
  .upload-input {
    max-height: 3rem !important;
    min-height: 3rem !important;
  }
  .upload-draggable {
    min-height: 3rem;
  }
  .flex {
    height: 4em;
  }
  .upload-file-thumbnail {
    width: 3.7rem;
    height: 3.5rem;
  }
  .upload-file-name {
    font-size: 14px;
  }
  .upload-file-info {
    min-height: unset;
  }
}

.my-16 {
  margin-top: -4.7em;
}
.upload-draggable-div {
  min-height: 7rem;
  max-height: 7rem;
}

.upload-draggable:focus {
  // border-color: #f97316 !important;
  // border-radius: 0.5rem;
  // border-style: dashed;
  outline: none;
}

.notification-badge-insurance-log {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(250, 102, 102);
  height: 25px;
  width: 25px;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 600;
  z-index: 0;
}

.field-array-class {
  display: flex;
  align-items: center;
  p:hover {
    color: rgb(61, 61, 248); /* Change text color to blue on hover */
  }

  & > *:last-child:hover {
    color: rgb(248, 83, 83); /* Change icon color to red on hover */
  }
}

.duty-taxt-calculator-div {
  .form-item {
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.tab-list-div {
  &.mobile {
    .tab-list {
      margin-top: 1em;
      display: block !important;
      .tab-nav-pill {
        min-width: 189.14px;
        margin: 1.2em 0.3em;
        width: auto;
        max-width: max-content;
        box-shadow:
          rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      }
    }
  }
}

.p-image-show {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.vsc-pdf-icon {
  &.show:hover {
    cursor: pointer;
    color: #ed2e34;
  }
}

//medis file upload
.upload:focus {
  border-color: #ea580c;
}

//confrim-diolog
// .confrim-diolog-div{
.p-confirm-popup {
  .p-confirm-popup-content {
    padding: 14px !important;
    span {
      font-size: 15px !important;
    }
  }
  .p-confirm-popup-footer {
    padding-bottom: 7px !important;
    .p-button {
      padding: 6px 14px !important;
    }
  }
}
// }

.input-textarea {
  overflow-y: auto;
}

.pointer {
  cursor: pointer !important;
}

.not-allowed {
  cursor: not-allowed;
}

[data-rsbs-scroll]::-webkit-scrollbar {
  display: none !important;
}

.default {
  cursor: default !important;
}

.no-scroll {
  overflow: hidden;
}

.custom_copy_div {
  display: flex;
  align-items: center;
  justify-content: end;
  ._label {
    &.--copy {
      cursor: pointer;
      // color: white;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 0.3em 0.8em;
      background: #ffffff;
      border-radius: 0.375rem;
      // border: 1px solid #f36118;
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }
    &.--copy:hover {
      background: #e9e7e7;
    }
  }
}

// auto form page info
.auto_form_row {
  .p-inputtext {
    min-width: 100px;
  }
}

.custom-login-card {
  height: 50px;
}

.file-view-div {
  button {
    border-radius: 34px !important;
    border: 1px solid #ea580c;
    box-shadow: 3px 4px 1px 0px #c1c1c1;
    color: #ea580c;

    &:hover {
      background: #fff;
    }
  }

  p {
    cursor: pointer;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;

    &:hover {
      color: #000000;
    }
  }

  &.--nothing {
    button {
      border: 1px solid #686868;
      color: #686868;
    }
  }
}

.icon-square {
  cursor: pointer;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 6px;
  display: grid;
  place-items: center;

  svg {
    width: 18px;
    height: 18px;
  }

  &.--share {
    color: #fafafa;
    background: #686868;

    svg {
      margin: 0 0 0.1em 0.1em;
    }
  }

  &.--delete {
    color: #ff4141;
    background: #ffdcdc;
  }
}

.upload-view-div {
  position: relative;
  overflow: hidden;

  &.--nothing {
    .upload-button {
      border: 1px solid #686868;

      p {
        color: #686868;
      }

      .upload-icon {
        background: #686868;
      }
    }
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ffffffb0;
  }

  .upload-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 14px;
    border: 1px solid #da0a0a;

    p {
      padding: 10px;
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #da0a0a;
    }

    .upload-icon {
      padding: 10px 12px;
      border-radius: 0 12px 12px 0;
      background: #da0a0a;

      svg {
        color: #fafafa;
      }
    }
  }

  .file-name {
    cursor: pointer;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;

    &:hover {
      color: #000000;
    }
  }
}

.hide {
  display: none !important;
}

.one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}
.file-preview-modal {
  @media (max-width: 480px) {
    height: max-content;
    max-height: 98%;
  }

  .dialog-content {
    &::-webkit-scrollbar {
      display: none !important;
    }

    @media (max-width: 480px) {
      padding: 1em;
      width: 360px;
      overflow-y: scroll;
    }
  }
}

.file-preview-modal {
  @media (max-width: 480px) {
    height: max-content;
    max-height: 98%;
  }

  .dialog-content {
    &::-webkit-scrollbar {
      display: none !important;
    }

    @media (max-width: 480px) {
      padding: 1em;
      width: 360px;
      overflow-y: scroll;
    }
  }
}

.file-preview {
  // width: 100%;
  display: grid;
  place-items: center;
  min-width: 472px;
  min-height: 500px;
  max-height: 500px;
  max-width: 472px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 0 0em;
  border: 1px solid #ddd;

  @media (max-width: 480px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 400px;
    max-height: 400px;
  }

  &.--full {
    padding-top: 1em;
    min-height: unset;
    min-width: unset;
    max-height: unset;
    max-width: unset;
    height: 100vh;
    width: 100%;
    place-content: center;
    overflow: hidden;
    // display: grid;
    border: 0;
    border-radius: unset;

    .video-container {
      height: 100% !important;
    }

    .photo-viewer-container {
      width: 100% !important;
      height: 100% !important;

      img {
        width: unset !important;
        height: unset !important;
      }
    }

    .pg-viewer-wrapper {
      width: 100%;
      height: 100%;
      // min-width: 100%;
      min-height: 100vh;
      min-width: 100vw !important;
      max-width: unset;
      max-height: unset;
      border-radius: 0;
      overflow-x: hidden;

      @media (max-width: 800px) {
        min-width: 400px;
      }

      .pg-viewer {
        width: 100%;
      }

      .pdf-canvas {
        place-content: center;
        display: grid;

        @media (max-width: 800px) {
          // width: 400px;
          padding-top: 4em;
        }
      }

      .loading-container {
        .loading {
          margin-top: 12em;
        }
      }

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

  iframe {
    width: 100%;
    height: 500px;
    overflow: hidden !important;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .p-image-show {
    height: 500px !important;

    img {
      object-fit: contain !important;
    }
  }

  .video-container {
    height: 500px !important;

    video {
      height: 100%;
    }
  }

  .pg-viewer-wrapper {
    max-width: 470px;
    min-width: 470px;
    border-radius: 10px;

    @media (max-width: 480px) {
      min-width: 400px;
      max-width: 400px;
    }

    .pg-viewer {
      width: 100%;
    }

    .pdf-canvas {
      display: grid;
      place-content: center;
    }

    .loading-container {
      .loading {
        margin-top: 12em;
      }
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .photo-viewer-container {
    width: 100% !important;
    height: 500px !important;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }

  .react-grid-Container {
    border-radius: 10px;
  }

  .react-grid-Grid {
    height: 200px !important;
    border-radius: 10px;
    border: 0;
  }

  .react-grid-Canvas,
  .react-grid-Viewport {
    height: 100% !important;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.social-icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: grid;
  place-items: center;

  &.--green {
    background: #e0f2e2;
  }

  &.--blue {
    background: #dae6f4;
  }

  &.--beige {
    background: #fef7e7;
  }
}

.p-dialog {
  .p-dialog-header {
    padding-left: 1em !important;
    padding-right: 1em !important;
    font-family:
      Inter,
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Arial,
      Noto Sans,
      sans-serif,
      Apple Color Emoji,
      Segoe UI Emoji,
      Segoe UI Symbol,
      Noto Color Emoji !important;
    padding-bottom: 1em !important;
  }

  .p-dialog-content {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .p-confirm-dialog-message {
    margin-left: 0em !important;
  }

  .p-button {
    background: #ea580c;
    border-color: #ea580c;

    &:hover {
      background: #ea580c !important;
      border-color: #ea580c !important;
    }

    .p-button-label {
      font-size: 0.9rem;
      font-weight: 600 !important;
      font-family:
        Inter,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji !important;
    }

    padding: 0.4em 1.2em;
  }

  .p-confirm-dialog-reject {
    .p-button-label {
      color: #ea580c;
    }
  }

  .p-button:enabled:active {
    background: #ea580c !important;
    border-color: #ea580c !important;
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px #ffc5a7,
      0 1px 2px 0 rgb(0, 0, 0);
  }
}

.upload-file-thumbnail {
  position: relative !important;
}

.upload-file-image {
  min-width: 44px;
  min-height: 40px;
}

.upload-file-preview {
  position: absolute !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  background: #00000080;

  &.--full {
    cursor: pointer;
    opacity: 1;
    width: 45px;
    height: 45px;
    z-index: 15;
    top: unset !important;
    background: #ebebebe3;
    backdrop-filter: blur(3px);
    border-radius: 100%;
    margin: 0.5em;

    svg {
      color: #ffffff !important;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &:hover {
    opacity: 1;
  }

  svg {
    color: #ffffff;
    width: 1.7rem;
    height: 1.7rem;
  }
}

.invoice-tooltip {
  font-size: 12px !important;
  min-width: max-content;
  // label {
  // }
}

.invoice-tooltip-wrapper {
  display: unset;
}

.preview-swiper {
  .swiper-wrapper {
    display: flex;
  }
}

.preview-swiper-prev {
  left: 0;
  margin-left: 0.8em;
}

.preview-swiper-next {
  right: 0;
  margin-right: 0.8em;
}

.preview-swiper-prev,
.preview-swiper-next {
  top: 43%;
  position: absolute;
  z-index: 5;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #ddd;
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.custom-scroll {
  // Additional styles for custom scroll
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar on WebKit browsers (optional)
  }

  scrollbar-width: thin; // For Firefox
  scrollbar-color: WhiteSmoke transparent; // For Firefox

  & {
    -webkit-overflow-scrolling: touch; // Optional: Smooth scrolling on iOS
    &::-webkit-scrollbar {
      display: none; // Hide scrollbar on WebKit browsers (optional)
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: lightgray;
      border-radius: 2px;
    }
  }
}

.collapsible {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.expanded {
  max-height: 4500px;
  transition: max-height 0.6s ease-in;
}

.custom-expandeble-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  color: #ea580c;
  border: 1.5px solid #ea580c85;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-expandeble-button:hover {
  background-color: #c2c2c238;
}

.custom-expandeble-button span {
  margin-left: 10px;
  font-size: 14px;
}

.lock-header {
  height: 100% !important;
  overflow-y: auto;
  width: 100%;
  padding: 0;
}

.lock-header thead > tr:first-child > th {
  background-color: #f3f3f3;
  z-index: 15;
  position: sticky;
  top: 0;
}

.lock-header > table {
  border-collapse: collapse;
  width: 100%;
}
.custom-date-picker {
  max-width: 17em;

  @media (max-width: 600px) {
    max-width: unset;
  }
}

.flatpickr-calendar {
  margin: auto;
  height: 270px;
  overflow: hidden;
}

/* TimeCalender.scss */

// Style the flatpickr calendar
.flatpickr-calendar {
  // Variables for easy theme customization
  $background-color: #f0f0f5;
  $selected-color: #7066e0;
  $hover-color: rgb(172, 172, 172);
  $text-color: white;
  $header-color: rgb(223, 223, 223);
  $border-radius: 10px;

  background-color: $background-color;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  // Style the calendar header
  .flatpickr-months {
    background-color: $header-color;
    color: $text-color;
    border-radius: $border-radius $border-radius 0 0;
  }

  // Style the navigation buttons
  .flatpickr-prev-month,
  .flatpickr-next-month {
    color: $text-color;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  // Style the selected date
  .flatpickr-day {
    &.selected,
    &.startRange,
    &.endRange,
    &:hover {
      background-color: $selected-color !important;
      color: $text-color !important;
      border-radius: 50%;
    }

    &:hover {
      background-color: $hover-color !important;
      color: $text-color !important;
    }
  }
}

.custom-container-auto-form {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: fit-content;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 1.5rem;
  border: 1px solid #d1d5db;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(0, 0, 0, 0.06);
}

// new dashboard

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gap-1\.5 {
  gap: 0.375rem;
}
.items-end {
  align-items: flex-end;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.flex-col {
  flex-direction: column;
}
.flex-auto {
  flex: 1 1 auto;
}

.dashboard-number {
  line-height: 1em;
}

@media (min-width: 1024px) {
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:items-center {
    align-items: center;
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:w-\[380px\] {
    width: 380px;
  }
  .lg\:items-center {
    align-items: center;
  }
}

.hoverable-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
}

.hoverable-btn:hover {
  background-color: #e2e2e2a2;
  border-radius: 50%;
  transform: scale(1.1);
}
.custom_option_item {
  &.is-select {
    background: #d7d8d942;
  }
}
.custom_option_item:hover {
  background: #d7d8d942;
}

.teep_mssage {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.one-line {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.media-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 5px;
  min-height: 300px;
  margin-top: 8em;
  @media (max-width: 600px) {
    // margin-top: -1em;
  }
  // max-height: 500px;
  // border: 1px solid #ddd;
  // border-radius: 8px;
  // background-color: #f9f9f9;

  // Styling for the image container
  .media-update-image {
    &.image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-bottom: 15px;

      img {
        max-width: 100%;
        max-height: 400px;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    &.video {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 90%;
      max-height: 80%;
      margin-bottom: 15px;

      .video-card {
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  // Styling for the document container
  .media-update-document {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;

    .document-item {
      border: 1px solid #e1e1e1;
      padding: 10px;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin: 5px;
      text-align: center;

      .document-col {
        justify-content: center;
        display: flex;
        margin-bottom: 5px;
      }

      label {
        display: block;
        font-size: 14px;
        color: #555;
      }
    }
  }

  // Styling for navigation buttons
  .media-toggle-buttons {
    @media (max-width: 450px) {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 2em;
      margin-top: 3em;
    }

    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      background-color: #ea580c;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      transition: background-color 0.3s ease;

      @media (max-width: 450px) {
        position: relative;
      }

      &.--prev {
        left: -10%;

        @media (max-width: 770px) {
          left: -5%;
        }

        @media (max-width: 450px) {
          left: 0;
        }

        svg {
          margin-left: -0.1em;
        }
      }

      &.--next {
        right: -10%;

        @media (max-width: 770px) {
          right: -5%;
        }

        @media (max-width: 450px) {
          right: 0;
        }

        svg {
          margin-right: -0.1em;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        color: #bfbfbf;
        background-color: #e1e1e1;
        cursor: not-allowed;
      }
    }
  }
}

.swal2-html-container {
  line-height: 1.5em !important;
}

.swal2-warning {
  border-color: #ffc475 !important;
  color: #ffc475 !important;
}

.swal2-title {
  line-height: 1.4em;
  padding: 0.5em 1em 0;
}

.swal2-actions {
  gap: 0.6em !important;
  margin: 1.2em auto 0.6em !important;

  .swal2-confirm {
    background: #ea580c;

    &:focus {
      box-shadow: 0 0 0 3px #ea580c4f;
    }
  }

  .swal2-cancel {
    background: #b3b5b7;
  }
}

.mt-50 {
  margin-top: 0.15em;
}

.mb-50 {
  margin-bottom: 0.15em;
}

.pin-bg {
  background: #e7621c30;
  color: #ea580c;
  width: 28px;
  height: 28px;
  border-radius: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -7px;
  right: -6px;
  backdrop-filter: blur(1px);
}

.red-hover {
  border-color: #fdafaf;

  &:hover {
    background: #dc262630;
  }
}

.green-hover {
  border-color: #8bd7c5;

  &:hover {
    background: #10bd5033;
  }
}

.vessel_radio_group {
  gap: 1.2em !important;
}

.border-transparent {
  border-color: transparent !important;
}

.pre {
  white-space: pre;
}

.payment-status-view-div {
  position: relative;
  overflow: hidden;

  &.--nothing {
    .upload-button {
      border: 1px solid #686868;

      p {
        color: #686868;
      }

      .upload-icon {
        background: #686868;
      }
    }
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ffffffb0;
  }

  .upload-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 14px;

    p {
      // padding: 10px;
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      // color: #da0a0a;
    }

    .upload-icon {
      padding: 10px 12px;
      border-radius: 0 12px 12px 0;
      // background: #da0a0a;

      svg {
        color: #fafafa;
      }
    }
  }
}

.update-frame {
  background: #ea580c0d;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  border-radius: 100%;
}
