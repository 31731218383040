//dc-admin
#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog {
    background: #9a9b9e30  !important;
    backdrop-filter: blur(5px) !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:after {
    border-top-color: #9a9b9e30  !important;
    top: 100.5% !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body h1 {
    color: #2b2e34 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .divider {
    border-bottom: 1px solid #e8580d !important;
    margin: 0.25em -0.6em !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .instructions {
    color: #2b2e34 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#unsubscribe-button {
    background: #ea580c !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#subscribe-button {
    background: #ea580c  !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button.action:hover {
    background: unset !important;
} 

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md {
    bottom: 70px;
    right: 20px;

    @media screen and (min-width: 429px) {
        bottom: 30px;
        right: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-message .onesignal-bell-launcher-message-body {
    font-size: 12px !important;

    @media screen and (min-width: 429px) {
        font-size: 14px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-sm {
    bottom: 70px;

    @media screen and (min-width: 429px) {
        bottom: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .push-notification {
    background: #ffffff;
}

.onesignal-fakebell-container {
    .onesignal-fakebell-button {
        cursor: pointer;
        width: 48.05px;
        height: 48.05px;
        border-radius: 100%;
        background: #33628f;
        position: fixed;
        bottom: 70px;
        right: 20px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);

        @media screen and (min-width: 429px) {
            bottom: 30px;
            right: 30px;
        }

        .pulse-ring {
            animation: notifyButtonPulse .35s ease-in-out;
            animation-iteration-count: 1;
            border-radius: 50%;
            bottom: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1000;
        }
    }
}

@keyframes notifyButtonPulse {
    0% {
        border-width: 10px;
        opacity: 0;
        transform: scale(.1);
    }
    50% {
        border-width: 5px;
        opacity: 1;
    }
    100% {
        border-width: 1px;
        opacity: 0;
        transform: scale(1.2);
    }
}

.dialog-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}
 
.add-to-home-dialog {
    position: relative;
    background: #333;
    color: white;
    padding: 1rem;
    border-radius: 8px;
    font-family: sans-serif;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.25);
    text-align: center;
    max-width: 100%;
}

.dialog-close {
    position: absolute;
    top: -7px !important;
    color: white;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    right: -7px !important;
    cursor: pointer;
    font-size: 18px;
    background-color: #fafafa !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-content {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // text-align: left !important;
}
 
.icon-plus {
    background-color: white;
    color: black;
    border-radius: 50%;
    padding: 10px;
    margin-right: 15px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.add-icon {
    width: 30px;
    height: 30px;

    @media (max-width: 600px) {
        width: unset;
        height: unset; 
    }
}
 
.share-icon {
    display: inline-block;
}
 
.add-to-home-dialog::after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent; 
    border-right: 15px solid transparent;
    border-top: 15px solid #333;
    transform: translateX(-50%);
}
 
@media (max-width: 600px) {
    .add-to-home-dialog {
        width: 90%;
    }
}
